'use strict';

/**
 * Load internal dependencies.
 */
import { createSwiper, getNavigationClasses } from './utils/swiper';
import { createGLightbox } from './utils/glightbox';
import { initMap } from './components/google-maps';
import { initFilter } from './components/house-filter';
import Loader from './components/Loader';
import { onToggleTab } from './components/toggle-tab';
import { onFaqTopicSelected } from './components/faqs';
import Offscreen, { onToggleOffscreen } from './components/Offscreen';

/**
 * Create featured slider, (navigation is included).
 */
createSwiper( 'featured', {
	navigation: getNavigationClasses( 'featured' ),
	slidesPerView: 'auto',
} );

/**
 * Create regio slider.
 */
createSwiper( 'regio', {
	navigation: getNavigationClasses( 'regio' ),
	slidesPerView: 'auto',
} );

/**
 * Create gallery lightbox
 */
createGLightbox( '.gallery__link', {
	loop: true,
	plyr: {
		youtube: {
			noCookie: true,
			rel: 0,
			showinfo: 0,
			iv_load_policy: 3,
		},
	},
} );

/**
 * Create Google Maps
 */
const maps = document.querySelectorAll( '.map__area' );
for ( const map of maps ) {
	initMap( map );
}

/**
 * Filter houses.
 */
const filters = document.querySelectorAll( '.filter__form' );
for ( const filter of filters ) {
	initFilter( filter );
}

/**
 * Create loaders.
 */
const loaders = document.querySelectorAll( '.loader' );
for ( const loader of loaders ) {
	new Loader( loader );
}

/**
 * Toggle tabbed content.
 */
const contentTabs = document.querySelectorAll( '.content-tabs' );
for ( const contentTab of contentTabs ) {
	contentTab.addEventListener( 'click', onToggleTab, false );
}

/**
 * Toggle faq topic.
 */
const toggleBtns = document.querySelectorAll( '.faq__toggle' );
for ( const btn of toggleBtns ) {
	btn.addEventListener( 'click', onFaqTopicSelected, false );
}

/**
 * Create offscreen element
 */
const offscreens = document.querySelectorAll( '.offscreen' );
for ( const offscreen of offscreens ) {
	new Offscreen( offscreen );
}

/**
 * Toggle offscreen elements
 */
const toggles = document.querySelectorAll( '.offscreen-toggle' );
for ( const toggle of toggles ) {
	toggle.addEventListener( 'click', onToggleOffscreen, false );
}
