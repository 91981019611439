/**
 * Event to be dispatched when loading starts.
 */
export const startLoadingEvent = new Event( 'hvspanje.loader.start', {
	bubbles: true,
} );

/**
 * Event to be dispatched when loading ends.
 */
export const endLoadingEvent = new Event( 'hvspanje.loader.end', {
	bubbles: true,
} );

/**
 * Class to construct loaders.
 */
export default class Loader {
	#loaderElement;

	/**
	 * Initialize Loader
	 *
	 * @param {HTMLElement} element
	 */
	constructor( element ) {
		this.#loaderElement = element;
		this.#addEvents();
	}

	/**
	 * Add events to the DOM.
	 */
	#addEvents = () => {
		document.addEventListener( 'hvspanje.loader.start', this.#show, false );
		document.addEventListener( 'hvspanje.loader.end', this.#hide, false );
	};

	/**
	 * Show loader.
	 */
	#show = () => {
		this.#loaderElement.classList.add( 'loader--active' );
	};

	/**
	 * Hide loader.
	 */
	#hide = () => {
		this.#loaderElement.classList.remove( 'loader--active' );
	};
}
