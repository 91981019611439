/* global hvspanje_request_object */

/** @type {WeakMap<HTMLElement, AbortController>} */
export const CONTROLLER_MAP = new WeakMap();

/**
 * Make Ajax request.
 *
 * @param {FormData}        body       Data to send with the request.
 * @param {AbortController} controller Request controller.
 */
export const doRequest = async ( body, controller = null ) => {
	// Make request.
	const response = await fetch( hvspanje_request_object.ajax_url, { // eslint-disable-line camelcase
		method: 'POST',
		body,
		controller,
	} );

	// Make sure response has an 2xx status.
	if ( ! response.ok ) {
		throw new Error( 'Request failed, response is not ok.' );
	}

	// Parse response.
	const { success, data } = await response.json();

	// Make sure response is succesful.
	if ( ! success ) {
		throw new Error( 'Request failed, response is unsuccesful.' );
	}

	// Return data.
	return data;
};
