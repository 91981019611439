'use strict';

/**
 * Toggle tabs
 *
 * @param {MouseEvent} event
 */
export const onToggleTab = ( { currentTarget, target } ) => {
	// Exit if we don't click a button.
	if ( target.nodeName !== 'BUTTON' ) {
		return;
	}

	// Set attributes for selected button.
	let clickedIndex = 0;
	const buttons = target.parentNode.children;
	for ( let i = 0, len = buttons.length - 1; i <= len; i++ ) {
		const child = buttons[ i ];

		if ( child === target ) {
			clickedIndex = i;
			child.setAttribute( 'aria-selected', true );
			child.classList.add( 'content-tabs__btn--active' );
		} else {
			child.setAttribute( 'aria-selected', false );
			child.classList.remove( 'content-tabs__btn--active' );
		}
	}

	// Hide all tabs.
	const tabs = currentTarget.children[ 1 ].children;
	for ( const tab of tabs ) {
		tab.classList.remove( 'content-tabs__tab--active' );
	}

	// Show active tab.
	tabs[ clickedIndex ].classList.add( 'content-tabs__tab--active' );
};
