'use strict';

/**
 * Toggle tabs
 *
 * @param {MouseEvent} event
 */
export const onFaqTopicSelected = ( { target } ) => {
	const targetTab = document.getElementById( target.getAttribute( 'aria-controls' ) );

	if ( ! targetTab ) {
		return;
	}

	// Toggle btn state.
	const btns = document.querySelectorAll( '.faq__toggle' );
	for ( const btn of btns ) {
		const isCurrentBtn = btn === target;
		btn.classList.toggle( 'faq__toggle--active', isCurrentBtn );
		btn.setAttribute( 'aria-selected', isCurrentBtn );
	}

	// Toggle tab state.
	const tabs = document.querySelectorAll( '.faq__panel' );
	for ( const tab of tabs ) {
		tab.classList.toggle( 'faq__panel--active', tab === targetTab );
	}
};
