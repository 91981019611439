/* global google */

'use strict';

import styles from '../utils/maps-style.js';

/**
 * Renders a Google Map onto the selected element
 *
 * @param {HTMLDivElement} map - The element that should contain the map
 *
 * @return {Object} The Google Map instance
 */
export const initMap = ( map ) => {
	const markers = map.querySelectorAll( '.map__marker' );

	const zoom = map.dataset?.zoom || 16;
	const args = {
		zoom: parseInt( zoom ),
		mapTypeId: google.maps.MapTypeId.ROADMAP,
		styles,
	};

	const area = new google.maps.Map( map, args );

	area.markers = [];
	for ( const marker of markers ) {
		initMarker( marker, area );
	}

	centerMap( area );

	return area;
};

/**
 * Creates a marker for the given element and map.
 *
 * @param {HTMLDivElement} marker - The marker element
 * @param {Object}         map    - The Google Map instance
 *
 * @return {void}
 */
export const initMarker = ( marker, map ) => {
	const { lat, lng, icon } = marker.dataset;

	const position = {
		lat: parseFloat( lat ),
		lng: parseFloat( lng ),
	};

	const feature = new google.maps.Marker( {
		position,
		map,
		icon: icon || '',
	} );

	map.markers.push( feature );

	if ( marker.innerHTML ) {
		const infowindow = new google.maps.InfoWindow( {
			content: marker.innerHTML,
		} );

		google.maps.event.addListener( feature, 'click', () => {
			infowindow.open( map, feature );
		} );
	}
};

/**
 * Centers the map showing all markers in view.
 *
 * @param {Object} map The Google Map instance.
 * @return {void}
 */
export const centerMap = ( map ) => {
	const bounds = new google.maps.LatLngBounds();

	for ( const marker of map.markers ) {
		bounds.extend( {
			lat: marker.position.lat(),
			lng: marker.position.lng(),
		} );
	}

	if ( map.markers.length === 1 ) {
		map.setCenter( bounds.getCenter() );
	} else {
		map.fitBounds( bounds );
	}
};
