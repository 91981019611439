'use strict';

export default class InstanceTracker {
	/** @type {WeakMap<HTMLElement, InstanceTracker>} */
	static #instances = new WeakMap();

	/**
	 * Create new InstanceTracker instance
	 *
	 * @param {HTMLElement} element - Element associated with the current instance
	 */
	constructor( element ) {
		InstanceTracker.#instances.set( element, this );
	}

	/**
	 * Get class instance based on associated HTML element
	 *
	 * @param {HTMLElement} element
	 * @return {InstanceTracker} InstanceTracker instance
	 */
	static getInstance( element ) {
		return InstanceTracker.#instances.get( element );
	}
}
