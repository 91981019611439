'use strict';

/**
 * Load internal dependencies.
 */
import InstanceTracker from '../utils/InstanceTracker';

export default class Offscreen extends InstanceTracker {
	#root;

	constructor( root ) {
		super( root );
		this.#root = root;
		this.#addEvents( root );
	}

	/**
	 * Add necessary events.
	 */
	#addEvents = () => {
		window.addEventListener( 'resize', this.close, false );
	};

	/**
	 * Open offscreen element.
	 */
	open = () => {
		// Set root attributes.
		this.#root.classList.add( 'offscreen--active' );
		this.#root.setAttribute( 'aria-hidden', false );
		this.#root.removeAttribute( 'inert' );

		// Set body attributes
		document.body.classList.add( 'scroll-lock' );
	};

	/**
	 * Close offscreen element.
	 */
	close = () => {
		// Set root attributes
		this.#root.classList.remove( 'offscreen--active' );
		this.#root.setAttribute( 'aria-hidden', true );
		this.#root.setAttribute( 'inert', true );

		// Set body attributes
		document.body.classList.remove( 'scroll-lock' );
	};

	/**
	 * Toggle offscreen element.
	 */
	toggle = () => {
		if ( this.#root.hasAttribute( 'inert' ) ) {
			this.open();
		} else {
			this.close();
		}
	};
}

/**
 * Toggle offscreen element.
 *
 * @param {MouseEvent} event
 */
export const onToggleOffscreen = ( { target } ) => {
	// Exit if no id is found.
	const id = target.getAttribute( 'aria-controls' );
	if ( ! id ) {
		return;
	}

	// Exit if no element is found.
	const element = document.getElementById( id );
	if ( ! element ) {
		return;
	}

	// Exit if no instance is found.
	const instance = Offscreen.getInstance( element );
	if ( ! instance ) {
		return;
	}

	instance.toggle();
	target.classList.toggle( 'offscreen-toggle--active' );
};
