'use strict';

/**
 * Load internal dependencies.
 */
import { startLoadingEvent, endLoadingEvent } from './Loader';
import { CONTROLLER_MAP, doRequest } from '../utils/request';

/**
 *	Submit filter form.
 *
 * @param {SubmitEvent} event
 */
export const onFormSubmit = async ( event ) => {
	event.preventDefault();
	const { currentTarget } = event;
	const container = document.querySelector( '.archive__results' );

	// Start loader.
	currentTarget.dispatchEvent( startLoadingEvent );

	// Abort previous request.
	if ( CONTROLLER_MAP.has( currentTarget ) ) {
		CONTROLLER_MAP.get( currentTarget ).abort();
	}

	// Get variables.
	const { nonce } = currentTarget.dataset;
	CONTROLLER_MAP.set( currentTarget, new AbortController() );

	// Create data.
	const body = new FormData( currentTarget );

	// Set data in url.
	const url = new URL( window.location.href.split( '?' )[ 0 ] );
	body.forEach( ( value, key ) => {
		url.searchParams.append( key, value );
	} );
	window.history.pushState( {}, '', url );

	// Neccessary to verify the request.
	body.set( 'action', 'filter_houses' );
	body.set( 'hvspanje_security', nonce );

	try {
		// Make request
		const data = await doRequest( body, CONTROLLER_MAP.get( currentTarget ) );

		if ( data ) {
			container.innerHTML = data;
		} else {
			container.innerHTML = '';
		}
	} catch ( error ) {
		// Silence.
	} finally {
		// Hide loader.
		currentTarget.dispatchEvent( endLoadingEvent );
	}
};

/**
 * Initialize form filter.
 *
 * @param {HTMLFormElement} form HTMLElement of our form.
 */
export const initFilter = ( form ) => {
	form.addEventListener( 'submit', onFormSubmit, false );
};
