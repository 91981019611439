'use strict';

/**
 * Create GLightbox instance with lazy loading.
 *
 * @param {string}           selector  Selector of HTML element.
 * @param {GLightboxOptions} [options] GLightbox options.
 *
 * @return {GLightbox} GLightbox instance.
 */
export const createGLightbox = async ( selector, options = {} ) => {
	const element = document.querySelector( selector );

	// Exit if element doesn't exist.
	if ( ! element ) {
		return;
	}

	// Load dependencies.
	const [ { default: GLightbox } ] = await Promise.all( [
		import( /* webpackChunkName: "glightbox" */ 'glightbox' ),
		import( /* webpackChunkName: "glightbox" */ 'glightbox/dist/css/glightbox.css' ),
	] );

	// Create GLightbox instance.
	return new GLightbox( {
		selector,
		...options,
	} );
};
