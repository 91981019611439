'use strict';

/**
 * Create Swiper instance with given prefix and lazy loading.
 *
 * @param {string}        prefix    Prefix to use for all classes.
 * @param {SwiperOptions} [options] Swiper options.
 *
 * @return {Swiper} Swiper instance.
 */
export const createSwiper = async ( prefix, options = {} ) => {
	const element = document.querySelector( `.${ prefix }__slider` );

	// Exit if element doesn't exist.
	if ( ! element ) {
		return;
	}

	// Load dependencies.
	const { default: Swiper, Navigation } = await import( /* webpackChunkName: "swiper" */ 'swiper' );

	// Create Swiper instance.
	return new Swiper( element, {
		modules: [ Navigation ],
		containerModifierClass: `${ prefix }--`,
		wrapperClass: `${ prefix }__wrapper`,
		noSwipingClass: `${ prefix }--no-swiping`,
		slideActiveClass: `${ prefix }__slide--active`,
		slideBlankClass: `${ prefix }__slide--blank`,
		slideClass: `${ prefix }__slide`,
		slideDuplicateClass: `${ prefix }__slide--duplicate`,
		slideDuplicateActiveClass: `${ prefix }__slide--duplicate-active`,
		slideDuplicateNextClass: `${ prefix }__slide--duplicate-next`,
		slideDuplicatePrevClass: `${ prefix }__slide--duplicate-prev`,
		slideNextClass: `${ prefix }__slide--next`,
		slidePrevClass: `${ prefix }__slide--prev`,
		slideVisibleClass: `${ prefix }__slide--visible`,
		...options,
	} );
};

/**
 * Get list of swiper classes for navigation.
 *
 * @param {string} prefix Prefix to use for all classes.
 *
 * @return {Object<string, string>} Object of classes.
 */
export const getNavigationClasses = ( prefix ) => {
	return {
		disabledClass: `${ prefix }__btn--disabled`,
		hiddenClass: `${ prefix }__btn--hidden`,
		lockClass: `${ prefix }__btn--locked`,
		prevEl: `.${ prefix }__btn--prev`,
		nextEl: `.${ prefix }__btn--next`,
	};
};
